@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    @apply p-2 bg-white dark:bg-dark3 relative;
  }
  .page-header {
    @apply text-xl lg:text-3xl text-gray-700 dark:text-white tracking-wide;
  }
  .text-link-primary {
    @apply hover:underline hover:opacity-80 hover:text-primary text-primary dark:hover:text-white dark:text-white;
  }

  /* .ant-input-currency {
    @apply hover:border-primary focus:border-primary 
  }

  .ant-input-currency {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 13px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }
  
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
  } */
}

@layer base {
  /* svg {
    vertical-align: baseline;
  } */

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 13px !important;
  }
}
