.multipleDropdownClassName-0 .ant-picker-dropdown {
  top: 0 !important;
}

.multipleDropdownClassName-1 .ant-picker-dropdown {
  top: 0 !important;
}

/* .multipleDropdownClassName-0 .ant-picker-status-success {
  display: none !important;
}

.multipleDropdownClassName-1 .ant-picker-status-success {
  display: none !important;
} */

.multipleDropdownClassName .ant-picker-cell-selected .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.85);

  background: transparent;
}

.multipleDropdownClassName
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: none;
}