.ant-layout-sider-children {
  position: fixed;
  height: 100%;
  width: 256px;
  transition: width 0.2s;
  display: flex;
  flex-direction: column;
}

.ant-layout-sider-trigger {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}

.ant-layout-sider-collapsed .ant-layout-sider-children {
  width: 80px;
}

.report-home .ant-list-item:hover {
  border: 1px solid #e53f6a !important;
  /* color: #08f !important; */
  cursor: pointer !important;
  margin: -1px !important;
  margin-bottom: 0px !important;
}
.report-home .ant-list-item:last-child:hover {
  margin-bottom: -1px !important;
}
.report-home .ant-list-item.non-link:hover {
  border: 0px solid #e8e8e8 !important;
  border-bottom: 1px solid #e8e8e8 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  cursor: not-allowed !important;
}

.ant-breadcrumb-separator {
  align-items: center;
  display: flex;
}

.ant-menu,
.ant-breadcrumb,
.ant-table,
.ant-select,
.ant-btn,
.ant-form-item-label > label,
.ant-input,
.ant-form label,
.ant-form-item,
.ant-picker-input > input {
  font-size: 13px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out, color 600000s 0s;
}

[data-color-scheme="dark"] {
  color-scheme: dark;
}

[data-color-scheme="light"] {
  color-scheme: light;
}