@media print {
  @page {
    size: A3 landscape;
  }

  .dark .card {
    background-color: white !important;
    border-color: white !important;
  }

  .ant-layout {
    background-color: white !important;
  }

  .ant-table {
    font-size: 18px !important;
    background-color: white !important;
  }

  th.ant-table-cell::before {
    background-color: white !important;
  }

  td.ant-table-cell {
    border-bottom: 1px solid #F0F0F0 !important;
  }

  .ant-table-cell {
    /* padding: 12px 8px !important; */
    background-color: white !important;
  }

  .ant-card-body {
    background-color: white !important;
    border-radius: 0 !important;
  }

  .ant-table-row {
    background-color: white !important;
  }

  .ant-table-summary tr {
    background-color: #F1F4F8 !important;
  }

  .ant-table-summary .ant-table-cell {
    white-space: nowrap;
    background-color: #F2F4F8 !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #F2F4F8 !important;
    color: black !important;
  }

  .ant-table-thead .ant-table-column-title {
    color: black !important;
  }

  .ant-table-custom {
    font-size: 18px !important;
  }

  #title-dashboard {
    color: black !important;
  }

  #legend-label-pie-chart-custom {
    color: black !important;
  }

  .ant-typography {
    color: black !important;
  }

  .page-header {
    color: black !important;
  }

  .ant-tabs-nav {
    position: static !important;
  }

  .ant-picker-calendar {
    background-color: #FFFFFF !important;
  }

  .ant-picker-panel {
    background-color: #FFFFFF !important;
  }
  
  .print-wrap div .card{
    background-color: white !important;
    color: black !important;
  }

  td, td * {
    color: black !important;
  }

  aside, header, footer, button, input, [class*="breadcrumbs"], .ant-alert, .ant-pagination, .ant-message, .ant-checkbox-wrapper, .ant-btn-group, .ant-calendar-picker, .ant-menu, .ant-input-affix-wrapper, .ant-table-column-sorter, .ant-dropdown-trigger, .ant-menu-submenu, .ant-menu-submenu-popup, .anticon-more, .anticon-setting, .ant-picker, .ant-card-bordered, .ant-avatar, .ant-select-selector, .ant-btn, .ant-radio-group {
    display: none !important;
  }

  .utils__content, .print {
    display: block !important;
  }

  html.dark body {
    background: white !important;
  }

  body {
    background-color: white;
    width: 1600px;
    height: 768px;
  }

  .print-wrap {
    flex-wrap: wrap;
  }

  .ant-tag {
    color: black !important;
  }

  #btn-add {
    display: none !important;
  }

   #meeting-info{
    display: none;
  }

  #dashboard-container{
    width: 55%;
  }

  #dashboard-parent{
    width: 100% !important;
    height: 100% !important;
  }

  #dashboard-content{
    margin-top: 25px !important;
  }

  #dashboard-chart{
    width: 100% !important;
    height: 100% !important;
  }

  .card {
    box-shadow: 0 0 #0000 !important;
  }
}