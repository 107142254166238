.ant-select-selection-0 > div {
  background-color: #A5F0E4 !important;
}

.ant-select-selection-1 > div {
  background-color: #FFE0A3 !important;
}

.ant-select-selection-2 > div {
  background-color: #D9F489 !important;
}

.ant-select-selection-3 > div {
  background-color: #FFA1AC !important;
}
