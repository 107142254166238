/* Custom color for the track */
.ant-slider-track {
  background-color: var(--primary-color) !important;
  opacity: 0.4;
}

/* Custom color for the handle */
.ant-slider-handle::after {
    background-color: var(--primary-color) !important;
    border: none;
    outline-offset: 2px;
    outline: var(--primary-color) solid 1px;
    box-shadow: 0 0 0 2px #FFFFFF !important; 
}

.ant-slider-handle:hover::after {
  box-shadow: 0 0 0 2px #FFFFFF !important; 
}

.ant-slider-handle:focus::after {
  box-shadow: 0 0 0 2px #FFFFFF !important; 
}